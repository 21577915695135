import XHR from '@/request/http'
const $h = new XHR()
import store from '@/store/store.js'
// url
const url = {
    lists:'/admin/v1/teachers/page',
    update:'/admin/v1/teachers/update',
    create:'/admin/v1/teachers/create',
    get:'/admin/v1/teachers/get',
    del:'/admin/v1/teachers/del',
    get_teacher:'/admin/v1/teachers/get',//获取
    download_teacher_template:'/admin/v1/teachers/template',//下载模板
    import_teacher:'/admin/v1/teachers/import',//导入
    export_teacher:'/admin/v1/teachers/export',//导出
    tree:'/admin/v1/teachers/tree',//获取学科下老师树
}


export async function lists(data)
{
    return $h.request(url.lists,data,'GET')
}

export async function create(data)
{
    return $h.request(url.create,data,'POST')
}

export async function edit(id)
{
    return $h.request(url.get_teacher,{id:id},'GET')
}

export async function update(data)
{
    return $h.request(url.update,data,'POST')
}

export async function del(data)
{
    return $h.request(url.del,data,'POST')
}

export async function get_teacher(data)
{
    return $h.request(url.get_teacher,data,'GET')
}

export function download_teacher_template()
{
    return $h.config.baseURL + url.download_teacher_template + '?Authorization='+store.state.user.token
}

export function import_teacher(data)
{
    return $h.config.baseURL + url.import_teacher;
}

export function export_teacher()
{
    return $h.config.baseURL+url.export_teacher + '?token='+store.state.user.token+"&"
}

export function tree()
{
    //筛选有老师的学科
    return $h.request(url.tree,'','GET').then(res=>{
        //console.log()
        let teachers = [];
        res.data && res.data.forEach(item => {
            let obj = {
                name:item.name,
                children:[]
            };

            item.children && item.children.forEach(itx=>{
                //
                let t = {
                    name:itx.name,
                    children:null
                };
                if(itx.teachers != null && itx.teachers.length) {
                    t.children = itx.teachers
                    obj.children.push(t)             
                }
            })
            
            //
            if(item.teachers != null && item.teachers.length) {
                obj.children = [...obj.children,...item.teachers]
            }
            
            if(obj.children.length) {
                teachers.push(obj)
            }
            
        });
        return {
            res,
            teachers
        }
    })
}